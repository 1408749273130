import React from 'react'
import { v4 } from 'uuid'
import { Stack, Flex, Button } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { format } from 'date-fns'

import LandingPage, {
  Header,
  Gallery,
  Faqs,
  Video,
  Checklist,
  Characteristics,
} from '../components/landingPage'

import { H1, P, Image, RowContent, ColImage, ColInfo, Section } from '../ui'
import ButtonBook from '../components/buttonBook'
import Features from '../components/features'
// import FloatingBook from '../components/floatingBook'

import logo from '../images/logo-white.png'

function ChecklistCTA() {
  return (
    <Checklist
      title="¿Estás listo para la aventura con tu familia?"
      subtitle="Una experiencia inolvidable para toda la familia. Aprovecha la promoción de Chichén Itzá ¡al mejor precio!."
      list={[
        'Incluye transportación para tu familia',
        '100% el mejor precio de Cancún',
        'Seguridad y confianza',
      ]}
    >
      <ButtonBook message="Hola, quiero la promoción de Chichen Itzá..." maxWidth="420px">
        Quiero ir a Chichén Itzá
      </ButtonBook>
    </Checklist>
  )
}

function SummerSpecialPage({ data }) {
  // const { t } = useTranslation()

  return (
    <LandingPage title="Promoción: Chichen Itzá Increíble">
      <Header
        image={
          <Image
            fluid={data.hero.childImageSharp.fluid}
            layout="background"
            style={{ opacity: 0.75 }}
          />
        }
      >
        <Stack
          spacing={4}
          maxWidth="560px"
          alignSelf={{ base: 'center', md: 'flex-start' }}
          justifyContent="center"
        >
          <Flex
            alignItems="flex-start"
            justifyContent="center"
            width="100%"
            maxWidth="200px"
            height="auto"
            width="100%"
            marginX="auto"
            opacity="0.85"
          >
            <img style={{ width: '100%', maxWidth: '80px' }} src={logo} />
          </Flex>
          <H1 color="white" fontSize="5xl" textAlign="center">
            Chichen Itzá: Tour Todo Incluído
          </H1>
          <P color="white" textAlign="center" fontSize="lg">
            Descubre el Tour más Famoso, Divertido e Interesante de la Cultura Maya. Para momentos
            inolvidables con tu familia al mejor precio.
          </P>
          <ButtonBook message="Hola, quiero la promoción de Chichen Itzá...">
            Quiero ir a Chichén Itzá
          </ButtonBook>
          <P textAlign="center" color="white" lineHeight="shorter" fontSize="sm">
            Términos y condiciones de la promoción.
          </P>
        </Stack>
      </Header>

      <Gallery
        title="Fotos de la experiencia"
        viewPhotos="Ver fotos"
        images={data.gallery.edges.map((image) => (
          <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
        ))}
      />

      <ChecklistCTA />

      {/* <Video
        title="Mira este vídeo sobre la experiencia"
        url="https://www.youtube.com/watch?v=E6RzRynbot8"
      /> */}

      <Characteristics
        title="¿Qué es lo que obtendrás en esta experiencia?"
        list={[
          {
            image: <Image fixed={data.why1.childImageSharp.fixed} layout="background" />,
            title: 'Visita a Valladolid',
            desc:
              'Conoce la ciudad colonial de Valladolid, enamórate de sus calles, parques y su icónica iglesia. Además de recargarte de energía positiva con un chamán maya.',
          },
          {
            image: <Image fixed={data.why2.childImageSharp.fixed} layout="background" />,
            title: 'Restaurante Real Mayab',
            desc: 'Comida Buffet con platillos internacionales y yucatecos. (No incluye bebida).',
          },
          {
            image: <Image fixed={data.why3.childImageSharp.fixed} layout="background" />,
            title: 'Chichen Itzá',
            desc:
              'Visita las ruinas mayas más famosas de la región, con guía certificado y 45 para que vayas a donde quieras.',
          },
          {
            image: <Image fixed={data.why4.childImageSharp.fixed} layout="background" />,
            title: 'Cenote Selva Maya',
            desc:
              'Después de visitar las ruinas disfruta de la experiencia refrescante y renovadora de un hermoso cenote.',
          },
        ]}
      />

      <ChecklistCTA />

      <Section title="¿De qué se tratá la experiencia?" titleAlign="center">
        <RowContent>
          <ColImage
            image={<Image fixed={data.family.childImageSharp.fixed} layout="background" />}
          />
          <ColInfo
            title="Conoce Chichén Itzá con Toda tu Familia"
            subtitle={
              <Stack spacing={4}>
                <P>
                  Descubre las ruinas de Chichen Itzá, una de las ciudades Mayas más majestuosas de
                  la historia por su tamaño, importancia y por ser la ubicación de una de las 8
                  nuevas maravillas del mundo, la pirámide de Kukulkán.
                </P>
                <P>
                  En este Tour, tú y tu familia visitarán las ruinas guiadas por un traductor
                  certificado y tendrán la oportunidad de conocer y de preguntar todo lo relacionado
                  con la cultura maya.
                </P>
                <P>
                  También por el camino podrán conocer la ciudad colonial de Valladolid, recargar
                  energía en un ritual maya (opcional), comer en un delicioso restaurante de comida
                  internacional y yucateca, además de visitar un cenote después de conocer Chichen
                  Itzá.
                </P>
                <P fontWeight="bold">
                  No podrás encontrar un tour Chichen Itzá más completo a este super precio.
                </P>
              </Stack>
            }
          />
        </RowContent>
      </Section>

      <Section title="¿Por qué reservar con nosotros?" titleAlign="center">
        <Features />
      </Section>

      <ChecklistCTA />

      <Section />

      {/* <Faqs
        title="Preguntas Frecuentes"
        list={[
          { question: 'Pregunta 1', answer: 'Respuesta 1' },
          { question: 'Pregunta 2', answer: 'Respuesta 2' },
          { question: 'Pregunta 3', answer: 'Respuesta 3' },
          { question: 'Pregunta 4', answer: 'Respuesta 4' },
        ]}
      /> */}

      {/* <FloatingBook
        title={t('landing:earlybooking.floatingBookNow.title')}
        desc={t('landing:earlybooking.floatingBookNow.desc')}
        colorScheme="red"
      /> */}
    </LandingPage>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "landing/chichen/hero-background.jpg" }) {
      ...bannerSectionImage
    }

    gallery: allFile(
      filter: { relativeDirectory: { eq: "landing/chichen/gallery" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }

    why1: file(relativePath: { eq: "landing/chichen/valladolid.jpg" }) {
      ...fixedImage
    }

    why2: file(relativePath: { eq: "landing/chichen/restaurante.jpg" }) {
      ...fixedImage
    }

    why3: file(relativePath: { eq: "landing/chichen/chichen.jpg" }) {
      ...fixedImage
    }

    why4: file(relativePath: { eq: "landing/chichen/selva-maya.jpg" }) {
      ...fixedImage
    }

    family: file(relativePath: { eq: "landing/chichen/chichen-family.jpg" }) {
      ...fixedImage
    }
  }
`

export default SummerSpecialPage
